<template>
    <div class="ml-10 mr-10">
        <Title :loading="loading" :text="this.$t('menu.logs')"></Title>

        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [5, 10, 25, 50, 100, -1],
            }"
            :headers="headers"
            :items="items"
            :items-per-page="25"
            :loading="loading ? 'secondary' : false"
            :loading-text="$t('loading')"
            :no-data-text="$t('no_data')"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1 mt-5"
        >

            <template v-slot:item.level="{item}">
                <v-chip v-if="item.level == 100" color="blue">
                    <v-icon left>
                        mdi-information
                    </v-icon>
                    DEBUG
                </v-chip>
                <v-chip v-else-if="item.level == 200" color="green">
                    <v-icon left>
                        mdi-information
                    </v-icon>
                    INFO
                </v-chip>
                <v-chip v-else-if="item.level == 250" color="orange">
                    <v-icon left>
                        mdi-alert-circle
                    </v-icon>
                    NOTICE
                </v-chip>
                <v-chip v-else-if="item.level == 300" color="orange">
                    <v-icon left>
                        mdi-alert
                    </v-icon>
                    WARNING
                </v-chip>
                <v-chip v-else-if="item.level == 400" color="red">
                    <v-icon left>
                        mdi-alert
                    </v-icon>
                    ERROR
                </v-chip>
                <v-chip v-else-if="item.level == 500" color="red">
                    <v-icon left>
                        mdi-alert
                    </v-icon>
                    CRITICAL
                </v-chip>
                <v-chip v-else-if="item.level == 550" color="red">
                    <v-icon left>
                        mdi-alert
                    </v-icon>
                    ALERT
                </v-chip>
                <v-chip v-else-if="item.level == 600" color="red">
                    <v-icon left>
                        mdi-aler-decagram
                    </v-icon>
                    EMERGENCY
                </v-chip>
            </template>


        </v-data-table>

    </div>
</template>

<script>
import Accounting from "../helpers/Accounting";
import Title from "@/components/TitleBar.vue";

export default {
    name: "Log",
    components: {Title},
    data() {
        return {
            totalItems: 0,
            options: {},
            items: [],
            loading: false,
            headers: [
                {
                    value: "channel",
                    text: this.$t("logs.channel"),
                    sortable: false
                },
                {
                    value: "level",
                    text: this.$t("logs.level"),
                    sortable: false
                },
                {
                    value: "message",
                    text: this.$t("logs.message"),
                    sortable: false
                },
                {
                    value: "recorded_at",
                    text: this.$t("logs.recorded_at"),
                    sortable: false
                }
            ]
        };
    },
    watch: {
        options: {
            handler() {
                this.fetchElements();
            },
            deep: true
        }
    },
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.logs"),
                to: "/logs",
                exact: true
            }
        ]);
        this.fetchElements();
    },
    methods: {
        fetchElements() {
            this.loading = true;
            const {page, itemsPerPage} = this.options;

            this.items = [];
            this.totalItems = 0;

            Accounting.get("/logs?page=" + page + "&per_page=" + itemsPerPage + "&fields=channel,level,message,recorded_at")
                .then(response => {
                    let data = response.data;

                    this.totalItems = data.total;
                    this.items = data.logs;

                    this.loading = false;
                })
                .catch(error => {
                    if (error.response) {
                        this.errors = error.response.data.errors;
                    } else if (error.request) {
                        this.errors = [this.$t("api.no_response")];
                    } else {
                        this.errors = [error.message];
                    }

                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>

</style>